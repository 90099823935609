import GdprHeaderBlob from "components/blobs/GdprHeaderBlob";
import Footer from "components/footer/Footer";
import Navbar from "components/navbar/Navbar";
import { websiteUrl } from "consts/SEOConsts";
import { Helmet } from "react-helmet-async";

const GdprPage = () => {
	return (
		<>
			<Helmet>
				<title>GDPR - IntegrAGE - Self-Assessment for 55+ Workers+</title>
				<meta name="description" content="IntegrAGE - Discover your strengths with self-assessment tool for workers aged 55+. Evaluate your skills in technology, workplace integration and well-being." />
				<link rel="canonical" href={`${websiteUrl}/gdpr`} />
			</Helmet>
			<Navbar />
			<main className="gdpr-page">
				<GdprHeaderBlob />
				<div className="content">
					<section className="section">
						<h2>Who we are</h2>
						<p>Univerzita Jana Evangelisty Purkyně v Ústí nad Labem, established by Act No. 314/1991 Coll.</p>
						<p>Pasteurova 3544/1, 400 96 Ústí nad Labem, Czechia</p>
						<p>IČ: 44555601</p>
					</section>
					<section className="section">
						<h2>Data Protection Officer</h2>
						<p>Mgr. Lucie Tesařová</p>
						<p>poverenec@ujep.cz</p>
						<p>Tel.: +420 724 113 710</p>
					</section>
					<section className="section">
						<h2>List of processed and stored personal data</h2>
						<p>We only process personal data that you provide to us.</p>
						<ul>
							{["Name, Surname", "E-mail", "Country", "Type of residence", "Age", "Gender", "Level of education", "Employment status", "Job sector", "Length of unemployment"].map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					</section>
					<section className="section">
						<h2>Purposes of the processing of Personal Data</h2>
						<p>We process your personal data for the following purposes:</p>
						<ul>
							<li>for the retrospective identification of the users</li>
							<ul>
								<li>Name, surname</li>
							</ul>
							<li>to send the results of the questionnaire and to verify the user's registration</li>
							<ul>
								<li>E-mail</li>
							</ul>
							<li>For research purposes (at the research stage, it will no longer be linked to a specific user - it will be used in an anonymized form)</li>
							<ul>
								{["Country", "Type of residence", "Age", "Gender", "Level of education", "Employment status", "Job sector", "Length of unemployment"].map((item, index) => (
									<li key={index}>{item}</li>
								))}
							</ul>
						</ul>
					</section>
					<section className="section">
						<h2>Legal basis for the processing of Personal Data</h2>
						<p>We process your personal data only on the basis of your consent.</p>
						<p>You can withdraw your consent to the processing of your personal data at any time using the contact form in your user account.</p>
					</section>
					<section className="section">
						<h2>Transfer of Personal Data to a third party</h2>
						<p>We do not transfer your personal data to third parties. We only pass on anonymized data to third parties for research purposes.</p>
					</section>
					<section className="section">
						<h2>Personal Data storing and processing time</h2>
						<p>Your personal data will be processed for a maximum period of 1 year after your last login or until your consent is withdrawn.</p>
						<p>In the event of a request for data erasure, personal data including the entire user history is deleted, however, anonymous data is retained for research purposes.</p>
					</section>
					<section className="section">
						<h2>Security measures</h2>
						<p>All communication between your device and our web servers is encrypted. Login credentials are hashed and all your data is stored only on servers in secure data centres with limited, carefully controlled and audited access.</p>
						<p>For research purposes, all data is anonymized using a random key generated by the system. Reverse identification is not allowed. Researchers only have access to anonymized data.</p>
					</section>
					<section className="section">
						<h2>Password security</h2>
						<p>We do not have your passwords in the system, we do not store them in the database. We only work with specially calculated hash keys generated by a hashing function.</p>
					</section>
					<section className="section">
						<h2>Rights related to the protection of personal data</h2>
						<p>In relation to your personal data, you have in particular the right to withdraw your consent to the processing of your personal data at any time, the right to rectify or supplement your personal data, the right to request the restriction of its processing, the right to object to or complain about the processing of your personal data, the right to access your personal data, the right to request the transfer of your personal data, the right to be informed of a breach of security of your personal data and the right to erasure of the personal data that we process in relation to you (the so-called "right to be forgotten").</p>
						<p>You are in control of your personal data primarily through your user account. Here you can withdraw your consent to the processing of your personal data and amend, add or delete any information about you.</p>
						<p>Alternatively, you can contact us via the contact form in your user account to request an amendment, completion, restriction of processing or deletion of your personal data, to send us an overview of your personal data or to object to the processing of your personal data.</p>
						<p>If you believe that we are processing your personal data unlawfully, you also have the right to lodge a complaint with the Data Protection Authority. However, we will be happy if you address your complaint with us first. You can always contact us easily via the contact form found in your user account or via our Data Protection Officer, whose contact details can be found at the beginning of this document.</p>
						<p>We will process your request without undue delay, but within one month at most. In exceptional cases, in particular due to the complexity of your request, we are entitled to extend this period by a further two months. We will, of course, inform you of any such extension and the reasons for it.</p>
					</section>
				</div>
			</main>
			<Footer />
		</>
	);
};

export default GdprPage;
